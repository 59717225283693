<template>
  <div>
    <el-row class="m-0" :gutter="20">
      <el-col :span="6">
        <el-button v-if="userHasRole('IncidentsEdit')" type="text" icon="el-icon-circle-plus-outline" @click="createModalShow()">Добавить</el-button>
      </el-col>
    </el-row>
    <el-table
        :data="functionCardsList"
        id="function_cards--list"
        v-loading="loading"
        style="width: 100%"
    >
      <el-table-column width="42px">
        <template slot-scope="props">
            <el-button  type="text" icon="el-icon-search" @click="showFunction(props.row.id)"></el-button>
        </template>
      </el-table-column>
      <el-table-column width="42px">
        <template v-if="userHasRole('IncidentsEdit')" slot-scope="props">
            <el-button  type="text" icon="el-icon-edit" @click="editFunction(props.row.id)"></el-button>
        </template>
      </el-table-column>
      <el-table-column width="100" label="Номер карточки"  prop="sortNumber">
      </el-table-column>
      <el-table-column label="Энергообьект" prop="energyObjectsName" width="200px">
      </el-table-column>
      <el-table-column  label="Оборудование/ЛЭП события">
        <template v-slot="scope">
          {{eventEquipment(scope.row)}}
        </template>
      </el-table-column>
      <el-table-column label="Фирменное устройство" prop="productAssetModelName" width="200px">
      </el-table-column>
      <el-table-column label="Функция РЗА" prop="secondEquipmentName">
      </el-table-column>
      <el-table-column label="Оценка действия" prop="estimationCode" width="150">
      </el-table-column>
      <el-table-column width="42px">
      <template v-if="userHasRole('IncidentsEdit')" slot-scope="scope">
          <el-button type="text" icon="el-icon-delete" @click="functionCardDelete(scope.row.id)"></el-button>
      </template>
      </el-table-column>
    </el-table>
    <event-function-card-modal
      v-if="functionCardModalVisible"
      v-model="functionCardModalVisible"
      :id="id"
      :eventCard="eventCard"
      :functionCard="functionCard"
      :editFunctionCard="editFunctionCard"
      :addFunctionCard="addFunctionCard"/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import functionCardsApi from '@/api/eventsAndIncidents/functionCards/';
import udFunctionCardsApi from '@/api/eventsAndIncidents/userDataEvents/functionCards';
import EventFunctionCardModal from '@/views/EventsAndIncidents/Events/Tabs/EventFunctionCardModal';
import formatters from '@/mixins/formatters.js';
import dateHelpers from '@/mixins/dateHelpers';

export default {
    name: 'Event',
    props: {
        id: { type: String, default: () => '' },
        functionCardsList: { type: Array, default: () => [] },
        eventCard: { type: Object, default: () => {} },
        getData: { type: Function, default: () => {} },
    },
    mixins: [formatters, dateHelpers],
    components: {
        EventFunctionCardModal,
    },
    data() {
        return {
            loading: false,
            editFunctionCard: false,
            addFunctionCard: false,
            functionCardModalVisible: false,
            functionCard: {},
        };
    },
    computed: {
      ...mapGetters('identity', ['userHasRole', 'userHasRoleGroup']),
    },
    watch: {
        functionCardModalVisible: {
            handler(val) {
                if (val === false && this.editFunctionCard === true) {
                    this.loading = true;
                    this.getData(this.eventCard.id).then(() => { this.loading = false; });
                }
            }
        },
    },
    methods: {
      eventEquipment(row) {
        if (row.primaryEquipmentName && !row.secondEquipmentPanelName) {
          return row.primaryEquipmentName;
        }
        if (!row.primaryEquipmentName && row.secondEquipmentPanelName) {
          return row.secondEquipmentPanelName;
        }
        if (row.primaryEquipmentName && row.secondEquipmentPanelName) {
          return `${row.secondEquipmentPanelName}; ${row.secondEquipmentPanelName}`;
        }
        return '';
      },
        async editFunction(id) {
            const getAction = this.userHasRoleGroup('ExternalUser')
              ? udFunctionCardsApi.getFunctionCard
              : functionCardsApi.getFunctionCard;

            const res = await getAction(id);
            this.functionCard = res.data;
            this.addFunctionCard = false;
            this.editFunctionCard = true;
            this.functionCardModalVisible = true;
        },
        async showFunction(id) {
            const getAction = this.userHasRoleGroup('ExternalUser')
              ? udFunctionCardsApi.getFunctionCard
              : functionCardsApi.getFunctionCard;

            const res = await getAction(id);
            this.functionCard = res.data;
            this.addFunctionCard = false;
            this.editFunctionCard = false;
            this.functionCardModalVisible = true;
        },
        createModalShow() {
            this.functionCard = {
                estimationId: null,
                secondEquipmentId: null,
                eventCardId: this.eventCard.id,
                organizationalReasons: [],
                selectTechnicalReasons: [],
                technicalReasons: [],
                misoperationReasons: [],
                cardInformation: [],
                primaryEquipmentName: this.eventCard.primaryEquipmentName,
                secondEquipmentPanelName: this.eventCard.secondEquipmentPanelName,
                secondEquipmentsInit: [],
                secondEquipmentCardFactoryNames: []
            };
            this.addFunctionCard = true;
            this.editFunctionCard = true;
            this.functionCardModalVisible = true;
        },
        async functionCardDelete(id) {
        let udExists;
        if (this.userHasRoleGroup('ExternalUser')) {
          udExists = false;
        } else {
          udExists = await functionCardsApi.checkUserDataFunctionCards(id);
        }

        if (udExists.data) {
            this.$confirm('Вы действительно хотите удалить запись? Функция РЗА связана с данными внешнего пользователя!', 'Удаление записи!', {
                confirmButtonText: 'ОК',
                cancelButtonText: 'Отмена',
                type: 'warning',
                }).then(async () => {
                const deleteAction = this.userHasRoleGroup('ExternalUser')
                  ? udFunctionCardsApi.deleteFunctionCard
                  : functionCardsApi.deleteFunctionCard;
                this.loading = true;
                await deleteAction(id).then(async () => {
                    this.getData(this.eventCard.id).then(() => { this.loading = false; });
                });
            });
        } else {
            this.$confirm('Вы действительно хотите удалить запись?', 'Удаление записи!', {
                confirmButtonText: 'ОК',
                cancelButtonText: 'Отмена',
                type: 'warning',
                }).then(async () => {
                const deleteAction = this.userHasRoleGroup('ExternalUser')
                  ? udFunctionCardsApi.deleteFunctionCard
                  : functionCardsApi.deleteFunctionCard;
                this.loading = true;
                await deleteAction(id).then(async () => {
                  this.getData(this.eventCard.id).then(() => { this.loading = false; });
                });
            });
        }
      },
    }
};

</script>
