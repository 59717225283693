import axios from 'axios';

export default {
  createIncident: (incident) => axios.post('/api/Incidents/Create', incident),
  updateIncident: (incident) => axios.post('/api/Incidents/Update', incident),
  getIncident: (id) => axios.get('/api/Incidents/Get/', { params: { id } }),
  getIncidentList: (params) => axios.post('/api/Incidents/GetMany/', params),
  getEventCardTreeByIncidentId: (id) => axios.get('/api/Incidents/GetEventCardTreeByIncidentId/', { params: { id } }),
  delete(id) { return axios.delete('/api/Incidents/Delete/', { params: { id } }); },
  restore(id) { return axios.delete('/api/Incidents/Restore/', { params: { id } }); },
  clone(id) { return axios.post('/api/Incidents/Clone/',  { id }); },
  unionIncidents: (incidentsId) => axios.get('/api/Incidents/UnionIncidents', { params: { incidentsId } }),
  getIncidentDictionary: () => axios.get('/api/Incidents/GetIncidentDictionary'),
  getIncidentJournal(pageNumber, pageSize, sortField, sortDesc, incidentId) {
    return axios.get('/api/Incidents/GetIncidentJournal', {
      params: {
        pageNumber, pageSize, sortField, sortDesc, incidentId
      }
    });
  },
};
