<script>
import { mapGetters } from 'vuex';
import formatters from '@/mixins/formatters.js';
import dateHelpers from '@/mixins/dateHelpers';
import SecondEquipmentPanelMapping
  from '@/views/EventsAndIncidents/EventMapping/SecondEquipmentPanelMapping.vue';
import SaveIcon from '@/assets/icons/save.svg';
import ChatsIcon from '@/assets/icons/chats.svg';
import ChatDrawer from '@/components/Chat/ChatDrawer.vue';
import EventFunctionCardModal
  from '@/views/EventsAndIncidents/Events/Tabs/EventFunctionCardModal.vue';
import EventCardCorrelationRepository from '@/repositories/EventCardCorrelationRepository';
import useVuelidate from '@vuelidate/core';
import { helpers, required } from '@vuelidate/validators';


export default {
  name: 'EventMappingModal',
  props: ['myStore'],
  setup: () => ({ v$: useVuelidate() }),
  components: {
    EventFunctionCardModal,
    SaveIcon,
    SecondEquipmentPanelMapping,
    ChatsIcon,
    ChatDrawer
  },
  mixins: [formatters, dateHelpers],
  async created() {
    await this.myStore.loadTechReasonsAsync();
  },

  data() {
    return {
      loading: false,
      openChat: false,
      faultedPhasesLimit: 1,
      functionCardModalVisible: false,
      editFunctionCard: true,
      addFunctionCard: true,
      id: null,
      eventCard: {},
      functionCard: {},
      allowedCorEventStatuses: [],
      getData: () => {
      },
    };
  },
  validations() {
    const eventCardDateValidator = (value) => {
      const minDate = new Date(2012, 0);
      const currentDate = new Date();
      if (value !== null && value < minDate) {
        return {
          $valid: false,
          $message: 'Дата не должна быть старше 01.01.2012'
        }; 
      } else if (value > currentDate) {
        return {
          $valid: false,
          $message: 'Дата не должна быть больше текущей'
        }; 
      } else if (value == null) {
        return {
          $valid: false,
          $message: 'Поле обязательно для заполнения'
        }; 
      }  else {
        return true;
      }
    };
    const localRules = {
      destEventCard: {
        eventTime: {
          eventCardDateValidator,
          $autoDirty: true
        },
      }
    };
    if (this.destEventCard) {
      localRules.destEventCard.eventTime.required = helpers.withMessage('Поле обязательно для заполнения', required);
    }
    return localRules;
    // return !this.v$.$invalid;
  },
  watch: {
    destEventCard: {
      handler() {
        this.myStore.reCompareCurrent();
        /**
         * Проверка соответствия
         */
        this.myStore.checkSepState(this.$notify);
        this.allowedCorEventStatuses = this.myStore.getAllowedCorSepStatuses(this.model.secondEquipmentCardCorrelationStatusId);
      },
      deep: true
    },
  },
  computed: {
    ...mapGetters('identity', ['user', 'userHasRole', 'userHasRoleGroup']),
    ...mapGetters('dictionaries', ['shortCircuitForms', 'shortCircuitPlaces', 'faultedPhases', 'cardInformations', 'eventTypes']),
    model() {
      return this.myStore.state;
    },
    destEventCard() {
      return this.myStore.state.destEventCard;
    },
    sourceEventCard() {
      return this.myStore.state.sourceEventCard;
    },
    eventCompare() {
      return this.myStore.state.eventCompare;
    },
    showCopyAll() {
      return !this.myStore.state.destEventCard && this.myStore.state.sourceEventCard;
    },
    showDestEvent() {
      return this.myStore.state.destEventCard;
    },
    showSourceEvent() {
      return this.myStore.state.sourceEventCard;
    },
    destSeps() {
      return this.model.hideRepeat ? this.model.destEventCard.secondEquipmentPanelCards.filter((sep) => !sep.isEqual) : this.model.destEventCard.secondEquipmentPanelCards;
    },
    sSeps() {
      return this.model.hideRepeat ? this.model.sourceEventCard.secondEquipmentPanelCards.filter((sep) => !sep.isEqual) : this.model.sourceEventCard.secondEquipmentPanelCards;
    },
    statusName() {
      return this.model.statusId ? this.model.statusName : this.model.currentStatusName;
    },
    statusEventName() {
      return  this.model.statusEventName;
    },
    statusId() {
      return this.model.statusId ? this.model.statusId : this.model.currentStatusId;
    },
    secondEquipmentCardCorrelationStatusId() {
      return this.model.currentStatusEventId ? this.model.currentStatusEventId : this.model.secondEquipmentCardCorrelationStatusId;
    },
    readOnlyEvent() {
      if (!this.myStore.state.sourceEventCard || !this.myStore.state.sourceEventCard.secondEquipmentPanelCards) return false;
      return this.myStore.state.sourceEventCard.secondEquipmentPanelCards.filter((x) => x.readOnly === true).length 
        === this.myStore.state.sourceEventCard.secondEquipmentPanelCards.length
        || (this.myStore.state.currentStatusId === 1 && this.userHasRoleGroup('ExternalUser'))
        || (this.myStore.state.currentStatusId === 2 && !this.userHasRoleGroup('ExternalUser'));
    }
  },

  methods: {
    copy(name) {
      if (name === 'number') {
        this.myStore.state.destEventCard = this.myStore.copyEvent();
        this.myStore.selected(this.myStore.state.destEventCard);
      }
      if (this.myStore.state.destEventCard && this.myStore.state.sourceEventCard) {
        switch (name) {
          case 'eventTime':
            this.myStore.state.destEventCard.eventTime = this.myStore.state.sourceEventCard.eventTime;
            break;
          default:
            this.myStore.state.destEventCard[name] = JSON.parse(JSON.stringify({ v: this.myStore.state.sourceEventCard[name] })).v;
            break;
        }
        // this.myStore.reload();
      }
    },
    async validate() {
      return !this.v$.$invalid;
    },
    async handleSave() {
      if (!this.destEventCard || !this.sourceEventCard) return;
      const err = this.myStore.validateEvent(this.myStore.state.destEventCard);
      if (!err.valid && err.errors && err.errors.length) {
        err.errors.forEach((el) => {
          this.$notify.error(el);
        });
        return;
      }
      if (!await this.validate()) return;

      if (this.myStore.state.statusId === null) {
        this.$notify.error('Не опеределен статус сопоставления');
        return;
      }

      this.loading = true;

      try {
        // if (this.destEventCard) {
        //   this.destEventCard.eventTime = this.toServerUtcDate(this.destEventCard.eventTime);
        // }
        await this.myStore.saveAsync();
        this.$message.success('Сохранено успешно');
        this.model.dialogOpen = false;
        this.$emit('delete');
      } catch (e) {
        if (e.code === 'verr') {
          if (this.myStore.prepareValidate(e.errors)) {
            this.$nextTick(this.validate);
          }
          this.$message.error('Ошибка при валидации');
        } else {
          this.$message.error(e.message);
        }
      } finally {
        this.loading = false;
      }
    },
    handleOpenRZA() {
      this.functionCardModalVisible = true;
    },
    handleClose() {
      this.model.dialogOpen = false;
      this.myStore.clear();
    },
    async handleChangeSourceVariant(id) {
      await this.myStore.loadSourceVariantAsync(id);
    },
    async handleChangeDestVariant(id) {
      await this.myStore.loadDestVariantAsync(id);
    },
    handleHideRepeat(v) {
      this.myStore.hideRepeat(v);
    },
    onChangeFaultedPhases() {
      this.destEventCard.faultedPhases = [];
      if (this.destEventCard.shortCircuitFormId === 2 || this.destEventCard.shortCircuitFormId === 3) {
        this.faultedPhasesLimit = 1;
      } else if (this.destEventCard.shortCircuitFormId === 4 || this.destEventCard.shortCircuitFormId === 6) {
        this.faultedPhasesLimit = 2;
      } else if (this.destEventCard.shortCircuitFormId === 5) {
        this.faultedPhasesLimit = 3;
        this.destEventCard.faultedPhases.push(...[1, 2, 3]);
      } else {
        this.faultedPhasesLimit = this.faultedPhases.length;
      }
    },
    createModalShow() {
      this.functionCard = {
        estimationId: null,
        secondEquipmentId: null,
        eventCardId: this.destEventCard.id,
        organizationalReasons: [],
        selectTechnicalReasons: [],
        technicalReasons: [],
        misoperationReasons: [],
        cardInformation: [],
        primaryEquipmentName: this.destEventCard.primaryEquipmentName,
        secondEquipmentPanelName: this.destEventCard.secondEquipmentPanelName,
        secondEquipmentsInit: [],
        secondEquipmentCardFactoryNames: []
      };
      this.addFunctionCard = true;
      this.editFunctionCard = true;
      this.functionCardModalVisible = true;
    },
    handleReload() {
      this.myStore.openByIdAsync(this.myStore.state.currentCorrelationId);
    },
    async handleDelete() {
      this.loading = true;
      try {
        await EventCardCorrelationRepository.deleteAsync(this.myStore.state.currentCorrelationId);
        this.$emit('delete');
        this.model.dialogOpen = false;
        this.$message.success('Удалено успешно');
      } catch (e) {
        this.$message.error(e.message);
      } finally {
        this.loading = false;
      }
    },
    handleSetState() {
      this.myStore.handleSetState(this.userHasRoleGroup('ExternalUser'));
    }

  },
};
</script>

<template>
  <el-dialog
    :visible.sync="model.dialogOpen"
    :fullscreen="true"
    :show-close="false"
    :close-on-click-modal="false"
    custom-class="d">
    <template v-slot:title>
      <div style="display: flex;">
        <el-button class="pt-0" type="text" icon="el-icon-close" @click="handleClose">Закрыть</el-button>
        <el-button :loading="loading" :disabled="(loading && destEventCard) || readOnlyEvent" type="text" @click="handleSave">
          <SaveIcon/>
          Сохранить
        </el-button>
        <el-popconfirm
          title="Вы действительно хотите удалить запись?" class="ml-1"
          @confirm="handleDelete()"
        >
          <template v-slot:reference>
            <el-button
              :disabled="!myStore.state.currentCorrelationId || myStore.state.currentCorrelationId=== myStore.GUID_EMPTY || readOnlyEvent"
              type="text" icon="el-icon-delete">
                Разорвать сопоставление
            </el-button>
          </template>
        </el-popconfirm>
        <el-checkbox style="left: 10%" v-model="model.hideRepeat" type="text" @change="handleHideRepeat">
          Скрыть совпадающие
        </el-checkbox>
        <div v-if="sourceEventCard" style="position: relative; left: 18%">
          <span style="display: inline-block; font-size: 14px; margin-right: 10px">Автор: </span>
          <el-input size="mini" disabled v-model="sourceEventCard.userName"></el-input>
          <span style="display: inline-block; font-size: 14px; margin-right: 10px; margin-left: 10px">Источник: </span>
          <el-input size="mini" disabled v-model="sourceEventCard.sourceName"></el-input>
        </div>
        <el-button :disabled="!myStore.state.currentCorrelationId" class="right-position" type="text" @click="openChat=true">
          <ChatsIcon/>
          Чат
        </el-button>
      </div>
    </template>
    <!-- <el-drawer
      title="I am the title"
      :visible.sync="openChat"
      :with-header="false">
      <span>Hi !</span>
    </el-drawer> -->
    <chat-drawer v-model="openChat" :correlationId="model.currentCorrelationId"></chat-drawer>
    <div>
      <el-row :gutter="5" class="mt-0 my-label-14" v-if="showDestEvent">
        <el-col :span="13">Статус сопоставления события: <span style="font-weight: bold;"
                :class="[ statusId ==3 || statusId==4  ? 'my-label-color-state-1'  : 'my-label-color-state-2']">
            {{ statusName }}
          </span>
        </el-col>
        <el-col :span="11">Статус сопоставления атрибутов события:
          <span style="font-weight: bold;" :class="[secondEquipmentCardCorrelationStatusId ==5 || secondEquipmentCardCorrelationStatusId ==6  ? 'my-label-color-state-1'  : 'my-label-color-state-2']">
            {{ statusEventName }}
            </span>
        </el-col>
      </el-row>
      <div>
        <el-row :gutter="5" class="mt-1">
          <!-- v-if="this.model.showDestVariant || model.showSourceVariant" -->
          <el-col :span="12">
            <el-row :gutter="5" class="mt-0" v-if="this.model.showDestVariant && (this.model.showDestVariant || model.showSourceVariant)">
              <el-col :span="this.myStore.state.labelSpan" class="my-label-14">Событий найдено
                {{ model.destVariants.length }} :
              </el-col>
              <el-col :span="this.myStore.state.dataSpan">
                <el-select popper-class="search-select" size="mini" clearable
                           @change="handleChangeDestVariant"
                           v-model="model.selectedDestVariantId"
                           placeholder=""
                           :disabled="readOnlyEvent">
                  <el-option v-for="item in model.destVariants"
                             :key="item.id"
                             :label="item.number"
                             :value="item.id">
                  </el-option>
                </el-select>
              </el-col>
            </el-row>
            <span v-else>Текущий статус сопоставления события: 
              <span :span="this.myStore.state.dataSpan">
                {{ model.currentStatusName }}
              </span>
            </span>            
          </el-col>
          <el-col :offset="1" :span="11">
            <el-row :gutter="5" class="mt-0" v-if="model.showSourceVariant">
              <el-col :span="this.myStore.state.labelSpan" class="my-label-14">Событий найдено
                {{ model.sourceVariants.length }} :
              </el-col>
              <el-col :span="this.myStore.state.dataSpan">
                <el-select popper-class="search-select" size="mini" clearable
                           @change="handleChangeSourceVariant"
                           v-model="model.selectedSourceVariantId"
                           placeholder="">
                  <el-option v-for="item in model.sourceVariants"
                             :key="item.id"
                             :label="item.number"
                             :value="item.id">
                  </el-option>
                </el-select>
              </el-col>
            </el-row>
            <!-- <el-row :gutter="5" class="mt-0" v-else>
              <span :span="this.myStore.state.labelSpan">Текущий статус сопоставления события:</span>
              <span :span="this.myStore.state.dataSpan">
                {{ model.currentStatusName }}
              </span>
            </el-row> -->
          </el-col>
          <el-col :span="1">&nbsp;</el-col>
          <el-col :span="11"><span
            :class="[ model.mappingCount>0 && model.mappingCount===model.totalMappingCount  ? 'my-label-color-state-1'  : 'my-label-color-state-2']">
            Сопоставлено устройств по событию: {{ model.mappingCount }} из {{
              model.totalMappingCount
            }}</span>
          </el-col>
        </el-row>
        <el-divider style="margin: 0!important;"></el-divider>
        <el-row :gutter="5" class="mt-0">
          <el-col :span="11">
            <el-row :gutter="5" class="mt-0" v-if="showDestEvent">
              <el-col :span="24" class="my-label-16" style="color: #3078bf">Текущее событие:
              </el-col>
              <el-col :span="this.myStore.state.dataSpan">
              </el-col>
            </el-row>
            <span v-else>&nbsp;</span>
          </el-col>
          <el-col :offset="2" :span="11">
            <el-row :gutter="5" class="mt-0" v-if="showSourceEvent">
              <el-col :span="24" class="my-label-16" style="color: #3078bf">Сопоставляемое событие:
              </el-col>

            </el-row>
          </el-col>

        </el-row>
        <el-row :gutter="5" class="mt-2">
          <el-col :span="12">
            <el-row :gutter="5" class="mt-0" v-if="showDestEvent">
              <el-col :span="this.myStore.state.labelSpan" class="my-label-14">Номер события:
              </el-col>
              <el-col :span="4">
                <el-input
                  readonly
                  size="mini"
                  v-model="destEventCard.number"></el-input>
              </el-col>
              <el-col :span="1">&nbsp;</el-col>
              <el-col :span="6" class="my-label-14" v-if="!this.myStore.isExternal">Номер аварии
              </el-col>
              <el-col :span="4" v-if="!this.myStore.isExternal">
                <el-link :href="`/events-incidents/incidents/${destEventCard.incidentId}`"
                         target="_blank">{{ destEventCard.incidentNumber }}
                </el-link>
              </el-col>
            </el-row>
            <span v-else>&nbsp;</span>
          </el-col>
          <el-col :span="1" class="my-flex-content-center">
            <el-button
              :disabled="readOnlyEvent"
              :class="{'my-event-card-move': eventCompare.number }"
              v-if="showCopyAll"
              size="mini"
              icon="el-icon-d-arrow-left"
              circle
              @click="copy('number')"
            ></el-button>
            <span v-else>&nbsp;</span>
          </el-col>
          <el-col :span="11">
            <el-row :gutter="5" class="mt-0" v-if="showSourceEvent">
              <el-col :span="this.myStore.state.labelSpan" class="my-label-14">Номер события:
              </el-col>
              <el-col :span="this.myStore.state.dataSpan">
                <el-input
                  size="mini"
                  v-model="sourceEventCard.number" readonly></el-input>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
        <el-row :gutter="5" class="mt-1">
          <el-col :span="12" v-if="destEventCard && destEventCard.primaryEquipmentId">
            <el-row :gutter="5" class="mt-0" v-if="showDestEvent">
              <el-col :span="this.myStore.state.labelSpan" class="my-label-14">Оборудование события:
              </el-col>
              <el-col :span="this.myStore.state.dataSpan">
                <el-input 
                  :disabled="readOnlyEvent"
                  :class="{'my-event-card-no-compare': eventCompare.primaryEquipmentName }"
                  size="mini"
                  v-model="destEventCard.primaryEquipmentName" readonly></el-input>
              </el-col>
            </el-row>
            <span v-else>&nbsp;</span>
          </el-col>
          <el-col :span="12" v-else>
            <el-row :gutter="5" class="mt-0" v-if="showDestEvent">
              <el-col :span="this.myStore.state.labelSpan" class="my-label-14">Оборудование события:
              </el-col>
              <el-col :span="this.myStore.state.dataSpan">
                <el-input
                  :disabled="readOnlyEvent"
                  :class="{'my-event-card-no-compare': eventCompare.secondEquipmentPanelName }"
                  size="mini"
                  v-model="destEventCard.secondEquipmentPanelName" readonly></el-input>
              </el-col>
            </el-row>
            <span v-else>&nbsp;</span>
          </el-col>
          <el-col :offset="1" :span="11" v-if="sourceEventCard && sourceEventCard.primaryEquipmentId">
            <el-row :gutter="5" class="mt-0" v-if="showSourceEvent">
              <el-col :span="this.myStore.state.labelSpan" class="my-label-14">Оборудование события:
              </el-col>
              <el-col :span="this.myStore.state.dataSpan">
                <el-input 
                  :disabled="readOnlyEvent"                           
                  :class="{'my-event-card-no-compare': eventCompare.primaryEquipmentName }"
                  size="mini"
                  v-model="sourceEventCard.primaryEquipmentName" readonly></el-input>
              </el-col>
            </el-row>
          </el-col>
          <el-col :offset="1" :span="11" v-else>
            <el-row :gutter="5" class="mt-0" v-if="showSourceEvent">
              <el-col :span="this.myStore.state.labelSpan" class="my-label-14">Оборудование события:
              </el-col>
              <el-col :span="this.myStore.state.dataSpan">
                <el-input
                  :disabled="readOnlyEvent"
                  :class="{'my-event-card-no-compare': eventCompare.secondEquipmentPanelName }"
                  size="mini"
                  v-model="sourceEventCard.secondEquipmentPanelName" readonly></el-input>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
        <el-row :gutter="5" class="mt-1">
          <el-col :span="12">
            <el-row :gutter="5" class="mt-0" v-if="showDestEvent">
              <el-col :span="this.myStore.state.labelSpan" class="my-label-14">Энергообъект:</el-col>
              <el-col :span="this.myStore.state.dataSpan">
                <el-input
                  readonly
                  :class="{'my-event-card-no-compare': eventCompare.energyObjectsName }"
                  size="mini"
                  v-model="destEventCard.energyObjectsName"
                ></el-input>
              </el-col>
            </el-row>
            <span v-else>&nbsp;</span>
          </el-col>
          <el-col :span="1">
            <span>&nbsp;</span>
          </el-col>
          <el-col :span="11">
            <el-row :gutter="5" class="mt-0" v-if="showSourceEvent">
              <el-col :span="this.myStore.state.labelSpan" class="my-label-14">Энергообъект:</el-col>
              <el-col :span="this.myStore.state.dataSpan">
                <el-input
                  size="mini"
                  :class="{'my-event-card-no-compare': eventCompare.energyObjectsName }"
                  v-model="sourceEventCard.energyObjectsName"
                  readonly
                ></el-input>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
        <el-row :gutter="5" class="mt-1"
                v-if="!model.hideRepeat ||(model.hideRepeat && eventCompare.eventTime)">
          <el-col :span="12">
            <el-row :gutter="5" class="mt-0" v-if="showDestEvent">
              <el-col :span="this.myStore.state.labelSpan" class="my-label-14">Дата и время события:
              </el-col>
              <el-col :span="this.myStore.state.dataSpan">
                <el-date-picker type="datetime"
                                :class="{'my-event-card-no-compare': eventCompare.eventTime }"
                                v-model="destEventCard.eventTime"
                                v-mask="'##.##.#### ##:##'"
                                size="mini"
                                placeholder="дд.мм.гггг чч:мм"
                                format="dd.MM.yyyy HH:mm"
                                style="width: 100%"
                                :picker-options="datePickerOptions"
                                :disabled="readOnlyEvent"/>
              </el-col>
            <div
              v-if="v$.destEventCard.eventTime.eventCardDateValidator.$invalid"
              class="my-error">
              {{
                v$.destEventCard.eventTime.eventCardDateValidator.$response.$message
              }}
            </div>
            </el-row>
            <span v-else>&nbsp;</span>
          </el-col>
          <el-col :span="1" class="my-flex-content-center">
            <el-button :class="{'my-event-card-move': eventCompare.eventTime }"
                       v-if="eventCompare.eventTime && showSourceEvent" size="mini"
                       icon="el-icon-d-arrow-left"
                       @click="copy('eventTime')"
                       circle :disabled="readOnlyEvent"></el-button>
            <span v-else>&nbsp;</span>
          </el-col>
          <el-col :span="11">
            <el-row :gutter="5" class="mt-0" v-if="showSourceEvent">
              <el-col :span="this.myStore.state.labelSpan" class="my-label-14">Дата и время события:
              </el-col>
              <el-col :span="this.myStore.state.dataSpan">
                <el-date-picker type="datetime" readonly
                                :class="{'my-event-card-no-compare': eventCompare.eventTime }"
                                v-model="sourceEventCard.eventTime"
                                v-mask="'##.##.#### ##:##'"
                                size="mini"
                                placeholder="дд.мм.гггг чч:мм"
                                format="dd.MM.yyyy HH:mm"
                                style="width: 100%"
                                :picker-options="datePickerOptions"/>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
        <el-row :gutter="5" class="mt-1"
                v-if="!model.hideRepeat ||(model.hideRepeat && eventCompare.eventTypeId)">
          <el-col :span="12">
            <el-row :gutter="5" class="mt-0" v-if="showDestEvent">
              <el-col :span="model.labelSpan" class="my-label-14">Тип события:</el-col>
              <el-col :span="model.dataSpan">
                <el-select popper-class="search-select" size="mini" clearable
                           :class="{'my-event-card-no-compare': eventCompare.eventTypeId }"
                           v-model="destEventCard.eventTypeId"
                           @change="onChangeFaultedPhases" :disabled="readOnlyEvent"
                           placeholder="">
                  <el-option v-for="item in eventTypes"
                             :key="item.id"
                             :label="item.name"
                             :value="item.id">
                  </el-option>
                </el-select>
              </el-col>
            </el-row>
            <span v-else>&nbsp;</span>
          </el-col>
          <el-col :span="1" class="my-flex-content-center">
            <el-button :disabled="readOnlyEvent"
              :class="{'my-event-card-move': eventCompare.eventTypeId }"
              v-if="eventCompare.eventTypeId  && showSourceEvent" size="mini"
              @click="copy('eventTypeId')"
              icon="el-icon-d-arrow-left" circle></el-button>
            <span v-else>&nbsp;</span>
          </el-col>
          <el-col :span="11">
            <el-row :gutter="5" class="mt-0" v-if="showSourceEvent">
              <el-col :span="this.myStore.state.labelSpan" class="my-label-14">Тип события:</el-col>
              <el-col :span="this.myStore.state.dataSpan">
                <el-select popper-class="search-select" size="mini" disabled
                           :class="{'my-event-card-no-compare': eventCompare.eventTypeId }"
                           v-model="sourceEventCard.eventTypeId"
                           placeholder="">
                  <el-option v-for="item in eventTypes"
                             :key="item.id"
                             :label="item.name"
                             :value="item.id">
                  </el-option>
                </el-select>
              </el-col>
            </el-row>
          </el-col>

        </el-row>
        <el-row :gutter="5" class="mt-1"
                v-if="!model.hideRepeat ||(model.hideRepeat && eventCompare.shortCircuitFormId)">
          <el-col :span="12">
            <el-row :gutter="5" class="mt-0" v-if="showDestEvent">
              <el-col :span="model.labelSpan" class="my-label-14">Вид КЗ:</el-col>
              <el-col :span="model.dataSpan">
                <el-select popper-class="search-select" size="mini" clearable
                           :class="{'my-event-card-no-compare': eventCompare.shortCircuitFormId }"
                           v-model="destEventCard.shortCircuitFormId"
                           @change="onChangeFaultedPhases" :disabled="readOnlyEvent"
                           placeholder="">
                  <el-option v-for="item in shortCircuitForms"
                             :key="item.id"
                             :label="item.code + ' (' + item.name + ')'"
                             :value="item.id">
                  </el-option>
                </el-select>
              </el-col>
            </el-row>
            <span v-else>&nbsp;</span>
          </el-col>
          <el-col :span="1" class="my-flex-content-center">
            <el-button :disabled="readOnlyEvent"
              :class="{'my-event-card-move': eventCompare.shortCircuitFormId }"
              v-if="eventCompare.shortCircuitFormId  && showSourceEvent" size="mini"
              @click="copy('shortCircuitFormId')"
              icon="el-icon-d-arrow-left" circle></el-button>
            <span v-else>&nbsp;</span>
          </el-col>
          <el-col :span="11">
            <el-row :gutter="5" class="mt-0" v-if="showSourceEvent">
              <el-col :span="this.myStore.state.labelSpan" class="my-label-14">Вид КЗ:</el-col>
              <el-col :span="this.myStore.state.dataSpan">
                <el-select popper-class="search-select" size="mini" disabled
                           :class="{'my-event-card-no-compare': eventCompare.shortCircuitFormId }"
                           v-model="sourceEventCard.shortCircuitFormId"
                           placeholder="">
                  <el-option v-for="item in shortCircuitForms"
                             :key="item.id"
                             :label="item.code + ' (' + item.name + ')'"
                             :value="item.id">
                  </el-option>
                </el-select>
              </el-col>
            </el-row>
          </el-col>

        </el-row>
        <el-row :gutter="5" class="mt-1"
                v-if="!model.hideRepeat ||(model.hideRepeat && eventCompare.shortCircuitPlaceId)">
          <el-col :span="12">
            <el-row :gutter="5" class="mt-0" v-if="showDestEvent">
              <el-col :span="this.myStore.state.labelSpan" class="my-label-14">Место КЗ:</el-col>
              <el-col :span="this.myStore.state.dataSpan">
                <el-select popper-class="search-select" size="mini" clearable :disabled="readOnlyEvent"
                           :class="{'my-event-card-no-compare': eventCompare.shortCircuitPlaceId }"
                           v-model="destEventCard.shortCircuitPlaceId" placeholder="">
                  <el-option v-for="item in shortCircuitPlaces.filter(t => t.actual)"
                             :key="item.id"
                             :label="item.code + ' (' + item.name + ')'"
                             :value="item.id">
                  </el-option>
                </el-select>
              </el-col>
            </el-row>
            <span v-else>&nbsp;</span>
          </el-col>
          <el-col :span="1" class="my-flex-content-center">
            <el-button :class="{'my-event-card-move': eventCompare.shortCircuitPlaceId }" :disabled="readOnlyEvent"
                       v-if="eventCompare.shortCircuitPlaceId  && showSourceEvent" size="mini"
                       @click="copy('shortCircuitPlaceId')"
                       icon="el-icon-d-arrow-left" circle></el-button>
            <span v-else>&nbsp;</span>
          </el-col>
          <el-col :span="11">
            <el-row :gutter="5" class="mt-0" v-if="showSourceEvent">
              <el-col :span="this.myStore.state.labelSpan" class="my-label-14">Место КЗ:</el-col>
              <el-col :span="this.myStore.state.dataSpan">
                <el-select popper-class="search-select" size="mini" disabled
                           :class="{'my-event-card-no-compare': eventCompare.shortCircuitPlaceId }"
                           v-model="sourceEventCard.shortCircuitPlaceId" placeholder="">
                  <el-option v-for="item in shortCircuitPlaces.filter(t => t.actual)"
                             :key="item.id"
                             :label="item.code + ' (' + item.name + ')'"
                             :value="item.id">
                  </el-option>
                </el-select>
              </el-col>
            </el-row>
          </el-col>

        </el-row>
        <el-row :gutter="5" class="mt-1"
                v-if="!model.hideRepeat ||(model.hideRepeat && eventCompare.faultedPhases)">
          <el-col :span="12">
            <el-row :gutter="5" class="mt-0" v-if="showDestEvent">
              <el-col :span="this.myStore.state.labelSpan" class="my-label-14">Поврежденные фазы:
              </el-col>
              <el-col :span="this.myStore.state.dataSpan">
                <el-select popper-class="search-select" size="mini" key="destFaultedPhases" :disabled="readOnlyEvent"
                           :class="{'my-event-card-no-compare': eventCompare.faultedPhases }"
                           :multiple-limit="faultedPhasesLimit"
                           clearable v-model="destEventCard.faultedPhases" multiple placeholder="">
                  <el-option v-for="item in faultedPhases"
                             :key="item.id"
                             :label="item.name"
                             :value="item.id">
                  </el-option>
                </el-select>
              </el-col>
            </el-row>
            <span v-else>&nbsp;</span>
          </el-col>
          <el-col :span="1" class="my-flex-content-center">
            <el-button :class="{'my-event-card-move': eventCompare.faultedPhases }"
                       v-if="eventCompare.faultedPhases  && showSourceEvent"
                       size="mini" :disabled="readOnlyEvent"
                       @click="copy('faultedPhases')"
                       icon="el-icon-d-arrow-left" circle></el-button>
            <span v-else>&nbsp;</span>
          </el-col>
          <el-col :span="11">
            <el-row :gutter="5" class="mt-0" v-if="showSourceEvent">
              <el-col :span="this.myStore.state.labelSpan" class="my-label-14">Поврежденные фазы:
              </el-col>
              <el-col :span="this.myStore.state.dataSpan">
                <el-select popper-class="search-select" size="mini" key="sourceFaultedPhases"
                           :class="{'my-event-card-no-compare': eventCompare.faultedPhases }"
                            v-model="sourceEventCard.faultedPhases" multiple disabled
                           placeholder="">
                  <el-option v-for="item in faultedPhases"
                             :key="item.id"
                             :label="item.name"
                             :value="item.id">
                  </el-option>
                </el-select>
              </el-col>
            </el-row>
          </el-col>

        </el-row>
        <el-row :gutter="5" class="mt-1"
                v-if="!model.hideRepeat ||(model.hideRepeat && eventCompare.cardInformation)">
          <el-col :span="12">
            <el-row :gutter="5" class="mt-0" v-if="showDestEvent">
              <el-col :span="this.myStore.state.labelSpan" class="my-label-14">Доп. информация:
              </el-col>
              <el-col :span="this.myStore.state.dataSpan">
                <el-select key="destCardInformation"
                  :class="{'my-event-card-no-compare': eventCompare.cardInformation }"
                  multiple
                  :disabled="readOnlyEvent"
                  v-model="destEventCard.cardInformation" size="mini">
                  <el-option
                    v-for="item in cardInformations"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                    :disabled="item.isDisabled"
                  />
                </el-select>
              </el-col>
            </el-row>
            <span v-else>&nbsp;</span>
          </el-col>
          <el-col :span="1" class="my-flex-content-center">
            <el-button :class="{'my-event-card-move': eventCompare.cardInformation }"
                       v-if="eventCompare.cardInformation  && showSourceEvent"
                       size="mini" :disabled="readOnlyEvent"
                       @click="copy('cardInformation')"
                       icon="el-icon-d-arrow-left" circle></el-button>
            <span v-else>&nbsp;</span>
          </el-col>
          <el-col :span="11">
            <el-row :gutter="5" class="mt-0" v-if="showSourceEvent">
              <el-col :span="this.myStore.state.labelSpan" class="my-label-14">Доп. информация:
              </el-col>
              <el-col :span="this.myStore.state.dataSpan">
                <el-select key="sourceCardInformation"
                  :class="{'my-event-card-no-compare': eventCompare.cardInformation }"
                  multiple disabled
                  v-model="sourceEventCard.cardInformation"
                  size="mini">
                  <el-option
                    v-for="item in cardInformations"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  />
                </el-select>
              </el-col>
            </el-row>
          </el-col>

        </el-row>
        <el-row :gutter="5" class="mt-1"
                v-if="!model.hideRepeat ||(model.hideRepeat && eventCompare.description)">
          <el-col :span="12">
            <el-row :gutter="5" class="mt-0" v-if="showDestEvent">
              <el-col :span="this.myStore.state.labelSpan" class="my-label-14">Описание события:
              </el-col>
              <el-col :span="this.myStore.state.dataSpan">
                <el-input size="mini"
                          :class="{'my-event-card-no-compare': eventCompare.description  }"
                          v-model="destEventCard.description "
                          type="textarea"
                          :rows="3" :disabled="readOnlyEvent"
                ></el-input>
              </el-col>
            </el-row>
            <span v-else>&nbsp;</span>
          </el-col>
          <el-col :span="1" class="my-flex-content-center">
            <el-button :class="{'my-event-card-move': eventCompare.description }"
                       v-if="eventCompare.description  && showSourceEvent"
                       size="mini" :disabled="readOnlyEvent"
                       @click="copy('description')"
                       icon="el-icon-d-arrow-left" circle></el-button>
            <span v-else>&nbsp;</span>
          </el-col>
          <el-col :span="11">
            <el-row :gutter="5" class="mt-0" v-if="showSourceEvent">
              <el-col :span="this.myStore.state.labelSpan" class="my-label-14">Описание события:
              </el-col>
              <el-col :span="this.myStore.state.dataSpan">
                <el-input size="mini"
                          :class="{'my-event-card-no-compare': eventCompare.description }"
                          readonly
                          v-model="sourceEventCard.description"
                          type="textarea"
                          :rows="3"
                ></el-input>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
        <el-row :gutter="5" class="mt-1"
                v-if="showDestEvent && destEventCard.secondEquipmentPanelCards &&  destEventCard.secondEquipmentPanelCards.length>0">
          <el-col :span="6">
            <el-button
              :disabled="loading || !destEventCard || destEventCard.id===myStore.GUID_EMPTY"
              v-if="userHasRole('IncidentsEdit')" type="text" icon="el-icon-circle-plus-outline"
              @click="createModalShow()">Добавить УРЗА/ФРЗА
            </el-button>
          </el-col>
          <el-col :span="6" style="justify-content: flex-end;display: flex;">
            <el-button type="text" @click="handleSetState">"Согласовано" по всем УРЗА</el-button>
          </el-col>
        </el-row>
      </div>

      <el-row :gutter="5" class="mt-1" v-if=" showDestEvent || showSourceEvent">
        <el-col :span="12">
          <el-tabs v-if="showDestEvent" v-model="myStore.state.selectedSepId"
                   @tab-click="this.myStore.handleSepChange">
            <el-tab-pane
              v-for="(sec, i) in destSeps"
              :key="i"
              :name="sec.secondEquipmentPanelId"
            >
              <span slot="label"> 
                <span v-if="!sec.readOnly">{{ sec.secondEquipmentPanelName.substr(0,25)+'...' }}</span>
                <span style="color: #c0c4cc;" v-else>{{ sec.secondEquipmentPanelName.substr(0,25)+'...' }}</span>
              </span>
            </el-tab-pane>

          </el-tabs>
          <span v-else>&nbsp;</span>
        </el-col>
        <el-col :span="1">&nbsp;</el-col>
        <el-col :span="11">
          <el-tabs v-if="showSourceEvent" v-model="myStore.state.selectedSepId"
                   @tab-click="this.myStore.handleSepChange">
            <el-tab-pane
              v-for="(sepc, i) in sSeps"
              :key="i"
              :name="sepc.secondEquipmentPanelId"
            >
              <span slot="label"> 
                <span v-if="!sepc.readOnly">{{ sepc.secondEquipmentPanelName.substr(0,25)+'...' }}</span>
                <span style="color: #c0c4cc;" v-else>{{ sepc.secondEquipmentPanelName.substr(0,25)+'...' }}</span>
              </span>
            </el-tab-pane>
          </el-tabs>
        </el-col>
      </el-row>
      <second-equipment-panel-mapping ref="sepRef"
                                      :my-store="myStore"></second-equipment-panel-mapping>
      <event-function-card-modal
        @function-card-added="handleReload"
        v-if="functionCardModalVisible"
        v-model="functionCardModalVisible"
        :editFunctionCard="editFunctionCard"
        :addFunctionCard="addFunctionCard"
        :eventCard="destEventCard"
        :functionCard="functionCard"
      ></event-function-card-modal>
    </div>
  </el-dialog>
</template>


<style>

.event-mapping-modal {
  margin: 0;
  height: 100% !important;

}

.right-position {
  position: absolute !important;
  right: 20px;
  top: 10px;
}

.el-main {
  overflow-y: auto !important;
}

.my-event-card-no-compare .el-input__inner {
  color: #E52B50 !important;
}

.my-event-card-no-compare .el-textarea__inner {
  color: #E52B50 !important;
}

.my-event-card-move .el-icon-d-arrow-left {
  color: #E52B50;
}

.my-label-color-state-1 {
  color: #008a00;
}

.my-label-color-state-2 {
  color: #E52B50;
}

.el-dialog__body {
  padding: 10px 20px !important;
}

.el-divider--horizontal {
  margin: 10px 0 !important;
}

</style>
