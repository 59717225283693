<template>
  <div>
    <el-table
      :data="secondEquipmentCardsList"
      v-loading="loading"
      style="width: 100%"
      :close-on-click-modal="false"
    >
      <el-table-column width="42px">
        <template slot-scope="props">
          <el-button type="text" icon="el-icon-search" @click="showModal(props.row.id)"></el-button>
        </template>
      </el-table-column>
      <el-table-column width="42px">
        <template v-if="userHasRole('IncidentsEdit')" slot-scope="props">
          <el-button type="text" icon="el-icon-edit" @click="showEditModal(props.row.id)"></el-button>
        </template>
      </el-table-column>
      <el-table-column width="100" label="Номер карточки" prop="sortNumber"/>
      <el-table-column label="Энергообьект" prop="energyObjectName"/>
      <el-table-column label="Устройство РЗА" prop="secondEquipmentName"/>
      <el-table-column label="Оценка действия" prop="estimationCode" width="180"/>
      <el-table-column width="42px">
      <template v-if="userHasRole('IncidentsEdit')" slot-scope="scope">
          <el-button type="text" icon="el-icon-delete" @click="secondEquipmentCardDelete(scope.row)"></el-button>
      </template>
      </el-table-column>
    </el-table>
    <second-equipment-card-modal
      v-model="dialogVisible"
      :eventCard="eventCard"
      :secondEquipmentCard="secondEquipmentCardItem"
      :edit-mode="modalEditMode"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import formatters from '@/mixins/formatters.js';
import dateHelpers from '@/mixins/dateHelpers';
import secondEquipmentCardsApi from '@/api/eventsAndIncidents/secondEquipmentCards';
import udSecondEquipmentCardsApi from '@/api/eventsAndIncidents/userDataEvents/secondEquipmentCards';
import functionCardsApi from '@/api/eventsAndIncidents/functionCards/';
import udFunctionCardsApi from '@/api/eventsAndIncidents/userDataEvents/functionCards';
import SecondEquipmentCardModal from '@/views/EventsAndIncidents/Events/Tabs/SecondEquipmentCardModal';

export default {
  name: 'EventSecondEquimentCards',
  props: {
    id: { type: String, default: () => '' },
    eventCard: { type: Object, default: () => {} },
    secondEquipmentCardsList: { type: Array, default: () => [] },
    getData: { type: Function, default: () => {} },
  },
  mixins: [formatters, dateHelpers],
  components: {
    SecondEquipmentCardModal
  },
  data() {
    return {
      loading: false,
      dialogVisible: false,
      modalEditMode: false,
      secondEquipmentCardItem: {},
    };
  },
  computed: {
    ...mapGetters('identity', ['userHasRole', 'userHasRoleGroup']),
  },
  watch: {
    secondEquipmentCardsList: {
      handler(val) {
        this.secondEquipmentCardsList = val;
      }
    },
    dialogVisible: {
        handler(val) {
            if (val === false) {
                this.loading = true;
                this.getData(this.eventCard.id).then(() => { this.loading = false; });
            }
        }
    },
  },
  methods: {
    async showModal(id) {
      const getAction = this.userHasRoleGroup('ExternalUser')
        ? udSecondEquipmentCardsApi.getSecondEquipmentCard
        : secondEquipmentCardsApi.getSecondEquipmentCard;

      const res = await getAction(id);
      this.secondEquipmentCardItem = res.data;
      this.modalEditMode = false;
      this.dialogVisible = true;
    },
    async showEditModal(id) {
      const getAction = this.userHasRoleGroup('ExternalUser')
        ? udSecondEquipmentCardsApi.getSecondEquipmentCard
        : secondEquipmentCardsApi.getSecondEquipmentCard;

      const res = await getAction(id);
      this.secondEquipmentCardItem = res.data;
      this.modalEditMode = true;
      this.dialogVisible = true;
    },
    async secondEquipmentCardDelete(data) {
        let udExists = '';
        if (this.userHasRoleGroup('ExternalUser')) {
          udExists = await udFunctionCardsApi.checkSecondEquipmentExtFunctionCards(data.secondEquipmentPanelId, this.eventCard.id);
        } else {
          udExists = await functionCardsApi.checkSecondEquipmentFunctionCards(data.secondEquipmentPanelId, this.eventCard.id);
        }
        let mess = 'Вы действительно хотите удалить запись?';
        if (udExists.data.length > 0) {
          let text = 'Будут удалены карточки срабатывания функций РЗА:';
          udExists.data.forEach((element, key) => {
          text += `<p>№${key + 1} &#8195; ${element }<p>`;
           mess = `Вы действительно хотите удалить запись? <p>${text}</p>`;
          });
        }
        await this.$confirm(mess, 'Удаление записи!', {
                confirmButtonText: 'ОК',
                cancelButtonText: 'Отмена',
                dangerouslyUseHTMLString: true,
                customClass: 'custom-confirm',
                type: 'warning',
                }).then(async () => {
                const deleteAction = this.userHasRoleGroup('ExternalUser')
                  ? udSecondEquipmentCardsApi.deleteSecondEquipmentCard
                  : secondEquipmentCardsApi.deleteSecondEquipmentCard;
                this.loading = true;
                await deleteAction(data.id).then(async () => {
                    this.getData(this.eventCard.id).then(() => { this.loading = false; });
                });
            });
      },
  },
};
</script>
<style>
.custom-confirm {
  width: 30% !important;
}
</style>
<style scoped lang="scss">
  .el-dialog__wrapper.el-dialog.el-dialog__body{
    padding: 20px;
  }
  .el-radio-group {
      font-size: initial !important;
  }
  .el-radio-group {
    width: 100%;
  }
  span.el-radio__label {
    padding-left: 5px;
  }
  span.el-radio__label>span {
    padding: 0px 5px;
  }
  span.el-radio__inner {
    margin: 0px 0px 9px 0px;
  }
  .el-tabs__header.is-top {
    margin: 0px !important;
  }
  /*button.el-button.el-button--text {
    color: #303133;
    margin: 0px 15px;
  }*/
  button.el-button.el-button--text:first-child {
    margin-left: 0px;
  }
  .el-col>span {
    font-size: 12px;
  }
  /*button.el-button.el-button--text:hover {
    color: #3078bf;
  }*/
  .el-select {
    width: 100%;
  }
  span {
    //white-space: nowrap;
    vertical-align: middle;
  }
  .dialog-footer {
    text-align: center;
  }
  .scrollableTree {
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 420px;
  }
  .tree-item>span>i {
    margin: 0px 5px 0px 0px;
  }
  .technical-reason--tree{
    font-size: 12px;
    .node__content {
      height: auto;
    }
    .custom-tree-node{
      .tree-item > span {
      white-space: normal;
      }
    }
  }
</style>
