<template>
  <div class="events_match">
    <div>
      <div class="m-2">
        <div style="display: inline-block;">
          <el-checkbox @change="applyFilter" v-model="filter.showSuccess">Успешные</el-checkbox>
          <el-checkbox @change="applyFilter" v-model="filter.showConflict">Конфликтные</el-checkbox>
          <el-checkbox @change="applyFilter" v-model="filter.showNew">Новые</el-checkbox>
          <el-checkbox @change="applyFilter" v-model="filter.showDivergence">Расхождения</el-checkbox>
        </div>
      </div>
    </div>
    <div>
      <el-row class="m-0" :gutter="20">
        <el-col :span="24">
          <pagination
            class="text-center"
            :total-count="totalCount"
            :page-size.sync="pageSize"
            :current-page.sync="pageNumber"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </el-col>
      </el-row>
      <el-table style="width: 100%;"
                v-loading="loading"
                class="compare-table"
                :data="itemsForCurrentPage"
                height="50vh"
                :row-class-name="getRowClassName"
                @selection-change="handleSelectionChange">
        <el-table-column prop="matchStatus"
                         label=""
                         >
          <template slot-scope="scope">
            <div style="text-align: center">
              <i v-if="scope.row.eventCardMatchStatus === matchStatuses.divergence"
                 style="font-size: 1.4rem"
                 class="event-compare-divergence">
                <QuestionIcon />
              </i>
              <i v-else-if="scope.row.eventCardMatchStatus === matchStatuses.conflict"
                 style="font-size: 1.4rem"
                 class="el-icon-warning-outline event-compare-conflict" />
              <i v-else-if="scope.row.eventCardMatchStatus === matchStatuses.newRow"
                 style="font-size: 1.4rem"
                 class="el-icon-circle-plus-outline event-compare-new" />
              <i v-else-if="scope.row.eventCardMatchStatus === matchStatuses.success"
                 style="font-size: 1.4rem"
                 class="el-icon-circle-check event-compare-success" />
              <i v-else-if="scope.row.eventCardMatchStatus === matchStatuses.undef"
                 style="font-size: 1.4rem"
                 class="el-icon-circle-plus-outline event-compare-new" />
            </div>
          </template>
        </el-table-column>
        <el-table-column label="Номер события" width="200">
          <el-table-column prop="publicEventCard.number"
                           label="СО ЕЭС"
                           width="100">
            <template slot-scope="scope">
              <span :class="[ scope.row.eventCardMatchStatus === matchStatuses.newRow ? 'event-compare-new' : '']" v-if="scope.row.publicEventCard">
                {{ scope.row.publicEventCard.number }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="userEventCard.number"
                           label="Субъект"
                           width="100">
            <template slot-scope="scope">
              <span :class="[ scope.row.eventCardMatchStatus === matchStatuses.newRow ? 'event-compare-new' : '']">
                {{ scope.row.userEventCard.number }}
              </span>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column label="Дата и время" >
          <el-table-column prop="publicEventCard.eventTime"
                           label="СО ЕЭС"

                           :formatter="dateTimeRenderer">
            <template slot-scope="scope">
              <div v-if="scope.row.publicEventCard">
                {{ moment(String(scope.row.publicEventCard.eventTime)).add(userTimeZone, 'hours').format('DD.MM.YYYY HH:mm:ss') }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="userEventTime"
                           label="Субъект"

                           class-name="manual-match">
            <template slot-scope="scope">
              <div>
                {{ moment(String(scope.row.userEventCard.eventTime)).add(userTimeZone, 'hours').format('DD.MM.YYYY HH:mm:ss') }}
              </div>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column v-if="userHasRoleGroup('InternalUser')"
                         label="GUID ПК «Анализ 2009»" width="250">
          <el-table-column prop="publicPrimaryEquipmentAnalysisIds"
                           label="СО ЕЭС"
                           width="125">
            <template slot-scope="scope">
              <span :class="[ scope.row.eventCardMatchStatus === matchStatuses.newRow ? 'event-compare-new' : '']" v-if="scope.row.publicEventCard">
                <template v-for="(analysisId, $index) in scope.row.publicEventCard.primaryEquipmentAnalysisIds">
                  <span :key="$index">
                    {{ analysisId }};
                  </span>
                </template>
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="userPrimaryEquipmentAnalysisIds"
                           label="Субъект"
                           width="125">
            <template slot-scope="scope">
              <span :class="[ scope.row.eventCardMatchStatus === matchStatuses.newRow ? 'event-compare-new' : '']">
                <template v-for="(analysisId, $index) in scope.row.userEventCard.primaryEquipmentAnalysisIds">
                  <span :key="$index">
                    {{ analysisId }};
                  </span>
                </template>
              </span>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column label="Наименование оборудования">
          <el-table-column prop="publicPrimaryEquipmentName"
                           label="СО ЕЭС"
                           >
            <template slot-scope="scope">
              <span v-if="scope.row.publicEventCard" :class="getEquipmentNameClassByStatus(scope.row)">
                {{ scope.row.publicEventCard.primaryEquipmentName }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="userPrimaryEquipmentName"
                           label="Субъект"
                           >
            <template slot-scope="scope">
              <span :class="getEquipmentNameClassByStatus(scope.row)">
                {{ scope.row.userEventCard.primaryEquipmentName }}
              </span>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column label="Описание">
          <el-table-column prop="publicDescription"
                           label="СО ЕЭС"
                           >
            <template slot-scope="scope">
              <div
                v-if="scope.row.publicEventCard"
              >
                {{ scope.row.publicEventCard.description && (scope.row.publicEventCard.description.length > 114) ? scope.row.publicEventCard.description.slice(0,115) + '...' : scope.row.publicEventCard.description }}
                <el-button v-if="scope.row.publicEventCard.description && (scope.row.publicEventCard.description.length > 114)" type="text" icon="el-icon-d-arrow-right" class="longtext__button"
                           @click="openLongText(scope.row.publicEventCard.description)"></el-button>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="userDescription"
                           label="Субъект"

                           class-name="manual-match">
            <template slot-scope="scope">
              <div>
                {{ scope.row.userEventCard.description && (scope.row.userEventCard.description.length > 114) ? scope.row.userEventCard.description.slice(0,115) + '...' : scope.row.userEventCard.description }}
                <el-button v-if="scope.row.userEventCard.description && (scope.row.userEventCard.description.length > 114)" type="text" icon="el-icon-d-arrow-right" class="longtext__button"
                           @click="openLongText(scope.row.userEventCard.description)"></el-button>
              </div>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column label="Вид КЗ">
          <el-table-column prop="publicShortCircuitFormCode"
                           label="СО ЕЭС" >
            <template slot-scope="scope">
              <span v-if="scope.row.publicEventCard">
                {{ scope.row.publicEventCard.shortCircuitFormCode }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="userShortCircuitFormCode"
                           label="Субъект" >
            <template slot-scope="scope">
              <span>
                {{ scope.row.userEventCard.shortCircuitFormCode }}
              </span>
            </template>
          </el-table-column>
        </el-table-column>

        <el-table-column label="Место КЗ" >
          <el-table-column prop="publicShortCircuitPlaceCode"
                           label="СО ЕЭС" >
            <template slot-scope="scope">
              <span v-if="scope.row.publicEventCard">
                {{ scope.row.publicEventCard.shortCircuitPlaceCode }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="userShortCircuitPlaceCode"
                           label="Субъект">
            <template slot-scope="scope">
              <span>
                {{ scope.row.userEventCard.shortCircuitPlaceCode }}
              </span>
            </template>
          </el-table-column>
        </el-table-column>

        <el-table-column label="Поврежденные фазы" min-width="200">
          <el-table-column prop="publicFaultedPhases"
                           label="СО ЕЭС" min-width="100">
            <template slot-scope="scope">
              <span v-if="scope.row.publicEventCard">
                {{ scope.row.publicEventCard.faultedPhasesText }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="userFaultedPhases"
                           label="Субъект" min-width="100">
            <template slot-scope="scope">
              <span>
                {{ scope.row.userEventCard.faultedPhasesText }}
              </span>
            </template>
          </el-table-column>
        </el-table-column>

        <el-table-column label="Доп. информация" min-width="200">
          <el-table-column prop="publicСardInformation"
                           label="СО ЕЭС" min-width="100">
            <template slot-scope="scope">
              <span v-if="scope.row.publicEventCard">
                {{ scope.row.publicEventCard.cardInformationText }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="userСardInformation"
                           label="Субъект" min-width="100">
            <template slot-scope="scope">
              <span>
                {{ scope.row.userEventCard.cardInformationText }}
              </span>
            </template>
          </el-table-column>
        </el-table-column>

        <el-table-column prop="functionCardMatches"
                         label="Функции РЗА"
                         >
          <template slot-scope="scope">
            <span class="span-link"
                  :class="getFunctionCardsClassByStatusAndReview(scope.row)"
                  @click="showFunctionCardsMatchModal(scope.row)">
              {{ getTextByMatchStatus(scope.row.functionCardsMatchStatus) }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="secondEquipmentCardMatches"
                         label="Устройства РЗА"
                         >
          <template slot-scope="scope">
            <span class="span-link"
                  :class="getSecondEquipmentCardsClassByStatusAndReview(scope.row)"
                  @click="showSecEqCardsMatchesModal(scope.row)">
              {{ getTextByMatchStatus(scope.row.secondEquipmentCardsMatchStatus) }}
            </span>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <function-cards-match-modal
      v-if="funcCardsMatchModalVisible"
      v-model="funcCardsMatchModalVisible"
      :items="matchList"
      :review-status="selectedItem.reviewStatus"
      :allow-match="selectedItem.isCurrentDC"
      :from-related-events="true"
      @all-ignore-handle="selectedItem.functionCardsReviewStatus = reviewStatuses.ignoredNotAccepted"
      @not-all-ignore-handle="selectedItem.functionCardsReviewStatus = reviewStatuses.notReviewed"
      @match-status-changed="handleFunctionCardsMatchStatusChange"
    />
    <second-equipment-cards-match-modal
      v-if="secEqCardsMatchModalVisible"
      v-model="secEqCardsMatchModalVisible"
      :items="matchList"
      :review-status="selectedItem.reviewStatus"
      :allow-match="selectedItem.isCurrentDC"
      :from-related-events="true"
      @all-ignore-handle="selectedItem.secondEquipmentCardsReviewStatus = reviewStatuses.ignoredNotAccepted"
      @not-all-ignore-handle="selectedItem.secondEquipmentCardsReviewStatus = reviewStatuses.notReviewed"
      @match-status-changed="handleSecondEquipmentCardsMatchStatusChange"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import eventsMatchApi from '@/api/eventsAndIncidents/eventsMatch';
import QuestionIcon from '@/assets/icons/question-icon.svg';
import tableResize from '@/mixins/tableResize';
import formatters from '@/mixins/formatters.js';
import pagination from '@/components/Ui/Pagination';
import SecondEquipmentCardsMatchModal from '@/views/EventsAndIncidents/EventsMatch/SecondEquipmentCardsMatchModal';
import eventCompare from '@/mixins/eventCompare';
import FunctionCardsMatchModal from './FunctionCardsMatchModal';

export default {
  name: 'RelatedEvents',
  props: {
    eventCardId: { type: String, default: () => null },
    readonly: { type: Boolean, default: () => false },
    tableSizeConstant: { type: Number, default: () => 145 },
  },
  components: {
    SecondEquipmentCardsMatchModal,
    FunctionCardsMatchModal,
    pagination,
    QuestionIcon
  },
  mixins: [formatters, tableResize, eventCompare],
  data() {
    return {
      loading: false,
      dataImportSession: null,
      // tableSizeConstant: 145,
      allRecords: [],
      filteredItems: [],
      itemsForCurrentPage: [],
      selectedItems: [],
      pageNumber: 1,
      pageSize: 50,
      totalCount: 0,
      filter: {
        showReviewed: true,
        showNotReviewed: true,
        searchByPrimaryEquipment: '',
        showSuccess: true,
        showConflict: true,
        showDivergence: true,
        showNew: true,
        showCurrentDC: true
      },
      funcCardsMatchModalVisible: false,
      secEqCardsMatchModalVisible: false,
      eventSelectModalVisible: false,
      selectedItem: null,  // элемент у которого в текущий момет просматриваются карточки ф-ии/ув/комплексов
      matchList: [],
      duplicatesModalVisible: false,
      duplicatesModalData: [],
      isDataChanged: false, // надо ли показывать окно "Все несохраненные данные будут утеряны"
    };
  },
  computed: {
    ...mapGetters('identity', ['userTimeZone', 'userHasRoleGroup']),
    isAcceptCompleted() {
      if (this.allRecords.length === 0) return false;

      return !this.allRecords.some((r) => r.reviewStatus === this.reviewStatuses.notReviewed
        || r.reviewStatus === this.reviewStatuses.ignoredNotAccepted);
    }
  },
  async mounted() {
    this.$nextTick(() => {
      this.tableHeight =  this.getTableHeight();
    });

    await this.getRelatedEvents();
    this.tableHeight = this.getTableHeight();
  },
  beforeRouteLeave(to, from, next) {
    if (!this.isDataChanged) {
      next();
    } else if (!this.isAcceptCompleted) {
      this.$confirm('Все несохраненные данные будут утеряны. Продолжить?', 'Подтверждение', {
        confirmButtonText: 'Да',
        cancelButtonText: 'Отмена',
        type: 'warning',
      }).then(() => {
        next();
      }).catch(() => {
        next(false);
      });
    } else {
      next();
    }
  },
  methods: {
    async getDataImportSession() {
      const res = await eventsMatchApi.getDataImportSession(
        this.id,
      );
      if (res.data) {
        this.dataImportSession = res.data;
      }
    },
    async getRelatedEvents() {
      this.loading = true;
      const res = await eventsMatchApi.getRelatedEvents(
        this.eventCardId,
      );
      if (res.data) {
        this.initTableData(res.data);
      }
      this.loading = false;
    },
    initTableData(matchItems) {
      this.allRecords = matchItems;
      this.applyFilter();
      this.getItemsForPage();
    },
    handleSizeChange() {
      this.pageNumber = 1;
      this.getItemsForPage();
    },
    handleCurrentChange() {
      this.getItemsForPage();
    },
    getFunctionCardsClassByStatusAndReview(row) {
      const classByStatus = this.getClassByMatchStatus(row.functionCardsMatchStatus);
      const classByIgnore = this.getClassByReviewStatus(row.functionCardsReviewStatus);
      return `${classByStatus  } ${  classByIgnore}`;
    },
    getSecondEquipmentCardsClassByStatusAndReview(row) {
      const classByStatus = this.getClassByMatchStatus(row.secondEquipmentCardsMatchStatus);
      const classByIgnore = this.getClassByReviewStatus(row.secondEquipmentCardsReviewStatus);
      return `${classByStatus  } ${  classByIgnore}`;
    },
    getEquipmentNameClassByStatus(row) {
      if (row.eventCardMatchStatus === this.matchStatuses.newRow) return 'event-compare-new';
      if (row.eventCardMatchStatus === this.matchStatuses.conflict
        && row.publicEventCard !== null
        && row.publicEventCard.primaryEquipmentId !== row.userEventCard.primaryEquipmentId
      ) return 'event-compare-conflict';
      return '';
    },
    applyFilter() {
      let tmp = this.allRecords;

      if (!this.filter.showReviewed) {
        tmp = tmp.filter((r) => r.reviewStatus !== this.reviewStatuses.reviewed
          && r.reviewStatus !== this.reviewStatuses.ignored);
      }
      if (!this.filter.showNotReviewed) {
        tmp = tmp.filter((r) => r.reviewStatus !== this.reviewStatuses.notReviewed
          && r.reviewStatus !== this.reviewStatuses.ignoredNotAccepted);
      }

      if (!this.filter.showSuccess) {
        tmp = tmp.filter((r) => r.eventCardMatchStatus !== this.matchStatuses.success);
      }
      if (!this.filter.showDivergence) {
        tmp = tmp.filter((r) => r.eventCardMatchStatus !== this.matchStatuses.divergence);
      }
      if (!this.filter.showNew) {
        tmp = tmp.filter((r) => r.eventCardMatchStatus !== this.matchStatuses.newRow);
      }
      if (!this.filter.showConflict) {
        tmp = tmp.filter((r) => r.eventCardMatchStatus !== this.matchStatuses.conflict);
      }
      if (this.filter.showCurrentDC) {
        tmp = tmp.filter((r) => r.isCurrentDC);
      }

      this.filteredItems = tmp;
      this.totalCount = this.filteredItems.length;
      this.getItemsForPage();
    },
    getItemsForPage() {
      this.itemsForCurrentPage = this.filteredItems.slice((this.pageNumber - 1) * this.pageSize, this.pageNumber * this.pageSize);
    },
    rowSelectable(row) {
      return (row.isCurrentDC === true);
    },
    handleSelectionChange(val) {
      this.selectedItems = val;
    },
    showFunctionCardsMatchModal(row) {
      this.selectedItem = row;
      this.matchList = row.functionCardMatchList;
      this.funcCardsMatchModalVisible = true;
    },
    showSecEqCardsMatchesModal(row) {
      this.selectedItem = row;
      this.matchList = row.secondEquipmentCardMatchList;
      this.secEqCardsMatchModalVisible = true;
    },
    openLongText(text) {
      this.$alert(text, '', {
        confirmButtonText: 'OK',
        customClass: 'longtext__modal'
      });
    },
  }
};
</script>

<style scoped lang="scss">
svg {
  path{
    stroke: #e6a23c;
  }
}
.el-table {
  // transition: all ease 0.2s;
}

.el-table--border {
  border-bottom: 1px solid #ebeef5;
  border-right: 1px solid #ebeef5;
}
.el-table:before {
  height: 0px;
}
.el-table:after {
  width: 0px;
}


/*.box2:hover {
  transform: translateY(3px);
  box-shadow: inset 0px 10px 20px 2px rgba(0, 0, 0, 0.25);
}*/
</style>
