<template>
  <el-dialog
    top="2em"
    width="70%"
    :visible.sync="dialogVisible"
    append-to-body
    class="types__second-equipments-modal"
    :close-on-click-modal="false"
  >
      <div slot="title" class="pt-0 pb-0">
        Функции устройств РЗА
      </div>
      <div class="my-flex-container-column"> 
        <second-equipments-filter
          @active-change="filterActive = $event"
          @clear-filters="clearFilters"
          :filter-is-active="filterIsActive"
          :filter-model="filterModel"
          :show-local-nsi-filter="showLocalNsiFilter"
          :show-all-filter="showAllFilter"
        />
        <second-equipments-table :data="selectedSecondEquipments" :filter-active="filterActive" :filter-model="filterModel" :from-event-card="this.fromEventCard"
          @on-update="selectedSecondEquipments = $event"
        />
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="selectSecondEquipments">Применить</el-button>
        <el-button type="default" @click="cancelSelectSecondEquipments">Отменить</el-button>
      </div>
  </el-dialog>
</template>

<script>
import SecondEquipmentsTable from './SecondEquipmentsSelectTable';
import SecondEquipmentsFilter from './SecondEquipmentsSelectFilter';

export default {
  name: 'SecondEquipmentsTreeModal',
  components: { SecondEquipmentsTable, SecondEquipmentsFilter },
  // props: ['value', 'data', 'primaryEquipmentId', 'hideFilter', 'showLocalNsiFilter', 'showAllFilter'],
  props: {
    value: { type: Boolean, default: () => false },
    data: { type: Array, default: () => [] },
    primaryEquipmentId: { type: String, default: () => null },
    secondEquipmentPanelId: { type: String, default: () => null },
    energyObjectName: { type: String, default: () => null },
    hideFilter: { type: Boolean, default: () => false },
    filterIsActive: { type: Boolean, default: () => true },
    showLocalNsiFilter: { type: Boolean, default: () => true },
    showAllFilter: { type: Boolean, default: () => false },
    fromEventCard: { type: Boolean, default: () => false }
  },
  computed: {
    dialogVisible: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  created() {
    if (this.data && this.data.length > 0) this.selectedSecondEquipments = this.data.map((t) => ({ name: t.value, baseId: t.id, ...t }));

    if (this.primaryEquipmentId) this.filterModel.primaryEquipmentIdFilter = this.primaryEquipmentId;
    if (this.secondEquipmentPanelId) this.filterModel.secondEquipmentPanelIdFilter = this.secondEquipmentPanelId;
    if (this.energyObjectName) this.filterModel.energyObjectNameFilter = this.energyObjectName;
    this.filterActive = this.filterIsActive;
  },
  data() {
    return {
      selectedSecondEquipments: [],
      filterModel: {
        primaryEquipmentIdFilter: null,
        secondEquipmentPanelIdFilter: null,
        nameFilter: '',
        psrTypeIdsFilter: [],
        energyObjectNameFilter: [],
        primaryEquipmentNameFilter: [],
        secondEquipmentPanelNameFilter: [],
        skipEoObject: false,
        isLocalNsiFilter: false,
        dispatchAdministrationOrganizationsFilter: [],
        dispatchManagementOrganizationsFilter: [],
        eoRegionFilter: [],
        ownerOrganizationsFilter: [],
        registrationGroupFilter: [],
        executionsFilter: [],
        techAdministrationOrganizationsFilter: [],
        techManagementOrganizationsFilter: [],
        technicalOrganizationsFilter: [],
        startCommissioningTimeRange: '',
        endCommissioningTimeRange: '',
        startDecommissioningTimeRange: '',
        endDecommissioningTimeRange: '',
        showAll: !this.primaryEquipmentId,
        showDecommissionedSE: null
      },
      filterActive: true
    };
  },
  methods: {
    selectSecondEquipments() {
      this.$emit('on-update', this.selectedSecondEquipments);
      this.dialogVisible = false;
    },
    cancelSelectSecondEquipments() {
      this.dialogVisible = false;
    },
    clearFilters() {
      this.filterModel = {
        showAll: !this.primaryEquipmentId,
        primaryEquipmentIdFilter: this.primaryEquipmentId ? this.primaryEquipmentId : null,
        secondEquipmentPanelIdFilter: this.primaryEquipmentId ? this.secondEquipmentPanelId : null,
        nameFilter: '',
        psrTypeIdsFilter: [],
        energyObjectNameFilter: [],
        primaryEquipmentNameFilter: [],
        secondEquipmentPanelNameFilter: [],
        skipEoObject: false,
        isLocalNsiFilter: false,
        dispatchAdministrationOrganizationsFilter: [],
        dispatchManagementOrganizationsFilter: [],
        eoRegionFilter: [],
        ownerOrganizationsFilter: [],
        registrationGroupFilter: [],
        executionsFilter: [],
        techAdministrationOrganizationsFilter: [],
        techManagementOrganizationsFilter: [],
        technicalOrganizationsFilter: [],
        startCommissioningTimeRange: '',
        endCommissioningTimeRange: '',
        startDecommissioningTimeRange: '',
        endDecommissioningTimeRange: '',
        showDecommissionedSE: null
      };
    }
  }
};
</script>

<style lang="scss">
.types__second-equipments-modal {
  min-height: 1000px;

  .el-dialog__header {
    padding-bottom: 0px;
  }
  .el-dialog__body {
    padding: 0px 10px 0px 10px;
    height: 80vh;
    max-height: 80vh;
  }
}

.dialog-footer {
  display: flex;
  justify-content: center;
}
</style>
