<template>
  <el-drawer
    title="Чат"
    :append-to-body="true"
    :before-close="handleClose"
    :visible.sync="drawerValue"
    :wrapperClosable="false"
    :with-header="true">
      <div id="chat-container" class="chat-container">
        <div id="chat-list-container" class="chat-list-container">
            <ChatList :chatItems="chats" @editChat="getEditChat" />
        </div>
        <el-card class="chat-input-container" shadow="always">
            <ChatInput v-model="correlationId" :idChat="idChat" @refresh="getCommentsByEventId()"  @updateMessage="updateChildMessage" />
        </el-card>
    </div>
  </el-drawer>
</template>

<script>
import chatApi from '@/api/chat';
import ChatInput from './ChatInput';
import ChatList from './ChatList';

  export default {
    props: {
      value: { type: Boolean, default: () => false },
      correlationId: { type: String, default: () => '' }
    },
    components: {
      ChatInput, 
      ChatList
    },
    computed: {
      drawerValue: {
        get() {
          if (this.value) {
            this.getCommentsByEventId();
            this.getCommentsInterval();
          } else {
            this.commentsDestroy();
          }
          return this.value;
        },
        set(val) {
          this.$emit('input', val);
        }
      }
    },
    watch: {
      correlationId() {
        this.idChat = '';
      }
    },
    data() {
      return {
        isLoaded: false,
        chats: [],
        idChat: '',
        intervalId: ''
      };
    },
    methods: {
      getCommentsInterval() {
        this.intervalId = setInterval(this.getCommentsByEventId, this.$intervalDurationChatLoad);
      },
      commentsDestroy() {
        this.chats = [];
        clearInterval(this.intervalId);
      },
      handleClose(done) {
        done();
      },
      async getCommentsByEventId() {
        this.isLoaded = true;
        const resp = await chatApi.getCommentsByEventId(this.correlationId); 
        this.chats = resp.data;
        this.isLoaded = false;
      },
      updateChildMessage() {
        this.idChat = '';
      },
      getEditChat(data) {
        this.idChat = data.id;
      }
    }
  };
</script> 
<style>
.chat-input-container{
  .el-card__body{
    padding: 0;
  }
}

.el-drawer.rtl .el-drawer__header{
  margin-bottom: 15px !important;
}
.el-drawer.rtl .el-drawer__body{
  overflow: unset !important;
}
.chat-container {
  width: 100%;
}
.chat-list-container {
  height: calc(100% - 140px);  
  width: inherit;
  overflow-y: auto;
}
.chat-input-container {
  width: inherit;
  height: 120px;
  position: absolute;
  bottom: 0;
}
</style>
