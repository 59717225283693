import axios from 'axios';

export default {
  getEventsWithPagination: (params) => axios.post('/api/ExtEvents/GetEventsWithPagination', params),
  getEventCard: (id) => axios.get('/api/ExtEvents/GetEventCard/', { params: { id } }),
  delete(id) {
    return axios.delete('/api/ExtEvents/Delete/', { params: { id } });
  },
  restore: (id) => axios.post('/api/UserDataEvents/Restore/', { id }),
  updateEvent: (event) => axios.put(`/api/ExtEvents/UpdateEvent/${event.id}`, event),
  createEvent: (eventCard) => axios.post('/api/ExtEvents/CreateEvent', eventCard),
  // getEventsWithPagination: (params) => axios.post('/api/UserDataEvents/GetUserDataEventsWithPagination', params),
  // getEventCard: (id) => axios.get('/api/UserDataEvents/GetEventCard/', { params: { id } }),
  // delete(id) { return axios.delete('/api/UserDataEvents/Delete/', { params: { id } }); },
  // restore: (id) => axios.post('/api/UserDataEvents/Restore/', { id }),
  // updateEvent: (event) => axios.put(`/api/UserDataEvents/UpdateEvent/${event.id}`, event),
  // createEvent: (eventCard) => axios.post('/api/UserDataEvents/CreateUserDataEvent', eventCard),
};
