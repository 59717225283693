<template>
  <el-row :gutter="20" :key="chat.id" :class="(chat.isActiveDirectory ? 'user1':'user2') + ' user-info message'">
      <!-- class="message user1" -->
      <el-col :span="16"><span class="name">{{senderName}}</span></el-col>
      <el-col :span="8" ><span class="date right-position" >{{ datetime }}</span></el-col>
      <el-col><p></p></el-col>
      <el-col><span class="text" style="white-space: pre-line; word-break: break-all;">{{chat.text}}</span></el-col>
  </el-row>
</template>
<script>
import moment from 'moment';
import { mapGetters } from 'vuex';

export default {
  name: 'Chat',
  props: ['chat'],
  computed: {
    ...mapGetters('identity', ['userTimeZone']),
    senderName() {
      return this.chat ? this.chat.userName : 'Unknown';
    },
    datetime() {
      return moment(this.chat.updateDt)
      .add(this.userTimeZone, 'hours')
      .format('DD.MM.YYYY HH:mm');
    }
  },
  mounted() {
    const element = document.getElementById('chat-list-container');
    if (typeof (element) !== 'undefined' && element != null) {
        element.scrollTop = element.scrollHeight;
    }
  }
};
</script>
<style>
  .message {
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 5px;
    justify-content: space-between;
    align-items: flex-start;
  }

  .user1 {
    background-color: #cfe2fc;
    color: #000000;
  }

  .user2 {
    background-color: #c9e2be;
    color: #000000;
  }

  .user-info {
    flex-grow: 1;
    margin: 5px !important;
  }

  .user-info .name {
    font-weight: bold;
  }

  .user-info .date {
    color: #888;
    font-size: 10px;
  }

  .message .text {
    margin-left: 10px;
  }

  .chat-input {
    display: flex;
    padding: 10px;
  }

  .input-field {
    flex: 1;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 3px;
  }
</style>
