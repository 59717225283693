<template>

  <el-dialog :visible.sync="modalVisible" title="Технические причины" width="60%" :close-on-click-modal="false" append-to-body>

    <div class="scrollableTree technical-reason--tree">

      <el-tree :data="tree"
               auto-expand-parent
               node-key="id"
               ref="tree"
               show-checkbox
               default-expand-all
               :default-checked-keys="selectedItems.map((r) => r.id)">

        <span class="custom-tree-node" slot-scope="{ node }">
          <div class="tdWraper">
            <div class="tree-item">
              <span>{{ node.data.code }} ({{ node.data.name }})</span>
            </div>
          </div>
        </span>
      </el-tree>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="save()">Применить</el-button>
      <el-button type="default" @click="back()">Отменить</el-button>
    </div>
  </el-dialog>

</template>

<script>

  import formatters from '@/mixins/formatters.js';
  import dateHelpers from '@/mixins/dateHelpers';

  export default {
    name: 'SelectTechnicalReasonsModal',
    mixins: [formatters, dateHelpers],
    props: {
      tree: { type: Array, default: () => [] },
      value: { type: Boolean, default: () => false },
      selectedItems: { type: Array, default: () => [] },
    },
    computed: {
      modalVisible: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit('input', val);
        },
      }
    },
    components: {
    },
    // watch: {
    //     selectedItems: {
    //         handler() {
    //             this.items = this.selectedItems;
    //         }
    //     }
    // },
    mounted() {
      this.items = this.selectedItems;
    },
    data() {
      return {
        items: []
      };
    },
    methods: {
      save() {
        this.items = this.$refs.tree.getCheckedNodes().filter((x) => x.children === null || x.children.length === 0);
        this.$emit('save', this.items);
        this.modalVisible = false;
      },
      back() {
        this.$refs.tree.setCheckedNodes([]);
        this.modalVisible = false;
      },
    },
  };
</script>

<style scoped lang="scss">
  .el-dialog__wrapper.el-dialog.el-dialog__body {
    padding: 20px;
  }

  .el-radio-group {
    font-size: initial !important;
  }

  .el-radio-group {
    width: 100%;
  }

  span.el-radio__label {
    padding-left: 5px;
  }

    span.el-radio__label > span {
      padding: 0px 5px;
    }

  span.el-radio__inner {
    margin: 0px 0px 9px 0px;
  }

  .el-tabs__header.is-top {
    margin: 0px !important;
  }
  /*button.el-button.el-button--text {
    color: #303133;
    margin: 0px 15px;
  }*/
  button.el-button.el-button--text:first-child {
    margin-left: 0px;
  }

  .el-col > span {
    font-size: 12px;
  }

  button.el-button.el-button--text:hover {
    color: #3078bf;
  }

  .el-select {
    width: 100%;
  }

  span {
    //white-space: nowrap;
    vertical-align: middle;
  }

  .dialog-footer {
    text-align: center;
  }

  .scrollableTree {
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 420px;
  }

  .tree-item > span {
    font-size: 14px;
  }

    .tree-item > span > i {
      margin: 0px 5px 0px 0px;
    }

  .technical-reason--tree {
    font-size: 12px;
    .node__content

  {
    height: auto;
  }

  .custom-tree-node {
    .tree-item > span

  {
    white-space: normal;
  }

  }
  }
</style>
